@media screen and (max-width: 1025px) {

  .bottom-annotation-menu {
    background-color: var(--sidebar-bg) !important;
    color: var(--text-hover-color);
    margin: auto;
    left: unset !important;
    right: 25% !important;
    /* width: fit-content; */
    display: table;

    border-radius: 10px 10px 0px 0px !important;
    transition: all 0.3s;

  }



}