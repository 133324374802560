
@media screen and (max-width: 765px) {
  .toolbar-intro-sudo-class {
    height: 0px;
    width: 0px;
    left: calc(100% - 155px);
    bottom: 48vh;
    position: absolute;
    zIndex: 10;
  }

}