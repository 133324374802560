@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700,800,900&display=swap');
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");

html {
  background-color: var(--body-bg);
}

/* Reset CSS for some objects*/

.select select.is-active,
.select select.is-focused,
.select select:active,
.select select:focus,
.button.is-focused:not(:active),
.button:focus:not(:active) {
  box-shadow: none;
}


.sidebar {
  position: relative;
  top: 0px;
  width: 160px;
  min-height: -webkit-fill-available;
  /* height: 100vh; */
  background: var(--sidebar-bg);
  transition: all 0.3s;
}

.searchbar {
  position: relative;
  top: 0px;

  width: 200px;
  /* height: 100vh; */
  min-height: -webkit-fill-available;
  background: var(--sidebar-bg);
  transition: all 0.3s;
  padding: 0;
  top: 0;
}
.is-full{
/* height: 100% !important; */
}

.ceni-logo {
  padding: 7px 12px 6px;
  background: var(--tools-icon-out-border);
  border-bottom: 1px solid var(--border-color);
}

.pdf-thumbnail-list {
  height: calc(100vh - 125px);
  /* Buradaki 80px .sidebar-footer elementinin yüksekliği */
  overflow-y: auto;
  padding: 10px 25px 0;
}


.searchbar-list {
  height: calc(100vh - 50px);
  /* Buradaki 80px .sidebar-footer elementinin yüksekliği */
  overflow-y: auto;
  padding: 0;
}

.searchbar .panel-heading {
  font-size: 16px;
  text-align: center;
  background: transparent;
  border: 0;
  color: #FFF;
  padding-top: 12px;
}

.searchbar .panel-block {
  padding: 8px;
  border: 0;
  ;
}

.searchbar input {
  padding: 5px;
}

.searchbar .control.has-icons-left .input,
.searchbar .control.has-icons-left .select select {
  padding-left: 5px;
}

.searchbar .columns {
  margin-left: 0;
  margin-right: 0;
}

.search-type-list {
  text-align: center;
  padding: 0 4px 6px;
  margin-bottom: 10px;
  border-bottom: 1px solid;
}

.search-type-list .panel-block {
  display: inline-block;
  width: 44px;
  height: 44px;
  padding: 4px;
  border-radius: 6px;
  margin: 1px;
}

.search-type-list .panel-block:hover {
  background: #45484E;
}

.search-type-list .panel-block:hover img {
  filter: brightness(120%)
}

.search-type-list .panel-block.is-active {
  background: #45484E;
}

.pdf-thumbnail {
  margin-bottom: 25px;
  transition: all 0.3s;
}

.pdf-thumbnail.active {
  border-top: 7px solid #c80000;
}

.pdf-thumbnail-link {
  position: relative;
  display: block;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
}

.pdf-thumbnail-link figure {
  display: block;
}

.pdf-thumbnail-link figure img {
  opacity: 0.75;
  transition: all 0.3s;
}

.pdf-thumbnail.active .pdf-thumbnail-link figure img {
  opacity: 1;
}

.pdf-thumbnail:hover {
  transform: scale(1.25);
  opacity: 1;
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: var(--sidebar-footer-bg);
  box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.1);
}

.searchbar-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background-color: var(--sidebar-footer-bg);
  box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.1);
}

.pdf-navigation {
  display: flex;
  justify-content: space-between;
  height: 40px;
  border-top: 1px solid var(--border-color);
  padding: 5px;
}

.first-page button,
.last-page button {
  display: inline-block;
  width: 28px;
  height: 28px;
  padding: 1px;
  border: 0;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  background: transparent;
  vertical-align: middle;
}

.first-page button:hover,
.last-page button:hover {
  background-color: var(--icon-hover-bg);
}

.select-area {
  margin-top: 2px;
  text-align: center;
  font-size: 14px;
}

.select-area .select {
  position: relative;
}

.select-area .icon {
  position: absolute;
  right: 3px;
  top: 6px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  pointer-events: none;
}

.select-area select {
  padding: 0 6px;
  width: 44px;
  height: 24px;
  background: transparent;
  border-color: var(--border-color) !important;
  border-radius: 3px;
  color: var(--text-color);
}

.select-area .select,
.select-area .total-page {
  display: inline-block;
}

.select-area .total-page {
  color: var(--text-color);
  font-size: 12px;
}

.select-area .select select:not([multiple]) {
  padding-right: 6px;
}

.select-area .select:not(.is-multiple):after {
  content: none;
}

.corporation {
  height: 40px;
  padding: 2px 5px;
  border-top: 1px solid var(--border-dark-color);
}

.corporation-link {
  display: inline-block;
  width: 32px;
  height: 28px;
  padding: 2px 2px 2px 3px;
  margin: 3px 0px 0px 1px;
  border-radius: 3px;
  border: 1px solid var(--border-color);
  outline: none;
  cursor: pointer;
  background-color: transparent;
}

.corporation-link img {
  width: 32px;
  height: 32px;
}

.collapse-sidebar {
  display: inline-block;
  width: 28px;
  height: 28px;
  padding: 1px;
  margin: 3px 0;
  border-radius: 3px;
  border: 1px solid var(--border-color);
  outline: none;
  cursor: pointer;
  background-color: transparent;
}

.collapse-searchbar {
  display: inline-block;
  width: 28px;
  height: 28px;
  padding: 1px;
  margin: 3px 0;
  border-radius: 3px;
  border: 1px solid var(--border-color);
  outline: none;
  cursor: pointer;
  background-color: transparent;
}

.collapse-sidebar:hover {
  background-color: var(--icon-hover-bg);
  border: transparent;
}

.collapse-searchbar:hover {
  background-color: var(--icon-hover-bg);
  border: transparent;
}

.content {
  background-color: var(--page-content-bg);
}

.contentCurtainWhite {
  background-color: #fff;
}

.contentCurtainBlack {
  background-color: #000;
}

.content ul {
  list-style: none;
  margin: 0;
}

.content li+li {
  margin-top: 0;
}

.pdf-page-list {
  position: relative;
  height: 100vh;
  cursor: pointer;
  overflow: auto;
  padding: 10px 15px 0 10px;
}

.pdf-page {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  margin-top: -30px;
}

.draw-canvas {
  /* border: 1px solid red; */
}

.canvas-no-pointer-events {
  pointer-events: none;
}

.pdf-page figure {
  display: block;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.75);
}

.pdf-page figure:not(:last-child) {
  margin: 0 0 50px;
}

.pdf-page figure:last-child {
  margin: 0;
}

.pdf-page figure img {
  display: block;
}

.pdf-page div {


  /* margin: auto !important; */
}


.interactive-object-area {
  position: absolute;
}

.interactive-object {
  position: relative;
  min-height: 28px;
  border-radius: 30px;
  border: 2px dotted;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  z-index: 10;
  width: max-content;
}

.interactive-object-without-border {}

.object-icon {
  position: absolute;
  left: -4px;
  top: -4px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
}

.midi .object-icon {
  left: 0px;
  top: 0px;
  width: 24px;
  height: 24px;
  border-radius: 24px;
}

.mini .object-icon {
  left: 0px;
  top: 0px;
  width: 16px;
  height: 16px;
  border-radius: 24px;
}

.object-icon svg {
  width: 32px;
  height: 32px;
}

.midi .object-icon svg {
  width: 24px;
  height: 24px;
  vertical-align: top !important;
}

.mini .object-icon svg {
  width: 16px;
  height: 16px;
  vertical-align: top !important;
}

.object-text {
  display: block;
  height: 24px;
  font-size: 11px;
  font-weight: bold;
  padding: 4px 0 4px 30px;
  /* Was 4px 0 4px 26px Mustafa changed to 30px */
  text-align: center;
  letter-spacing: -0.5px;
  margin-left: 5px;
  margin-right: 10px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.interactive-object-area.style1 .interactive-object-2 {
  background-color: transparent;
}


.interactive-object-area.style1 .icon-only svg circle {
  stroke: none !important;
}

.interactive-object-area.style2 .icon-only svg circle {
  stroke: none !important;
}

.interactive-object-area.style3 .icon-only svg circle {
  stroke: none !important;
}

.interactive-object-area.style4 .icon-only svg circle {
  stroke: none !important;
}

.interactive-object-area.style1 {
  border-color: #6884ab;
}

.interactive-object-area.style1 .interactive-object {
  background-color: #8eb0e1;
}

.interactive-object-area.style1 .object-icon svg circle {
  fill: #fff;
  stroke: #fd6d87;
}

.interactive-object-area.style1 .object-icon svg path {
  fill: #fd6d87;
}

.interactive-object-area.style1 .object-text {
  color: #fff;
}

.interactive-object-area.style2 {
  border-color: #1f222e;
  /* background-color: #010201; */
}

.interactive-object-area.style2 .interactive-object {
  background-color: #4a5067;
}

.interactive-object-area.style2 .object-icon svg circle {
  /* fill: #ffa800; */

  fill: #02a718;
  stroke: #050303;
}

.interactive-object-area.style2 .object-icon svg path {
  fill: #fff;
}

.interactive-object-area.style2 .object-text {
  color: #fff;
}

.interactive-object-area.style3 {
  border-color: #ffffff;
  /* background-color: #010201; */
}

.interactive-object-area.style3 .interactive-object {
  border-color: #DE3E5B;
  background-color: #010201;
}

.interactive-object-area.style3 .object-icon svg circle {
  fill: #ce242a;
  stroke: #ce242a;
  /* border-color: #ffffff;
  border: 2px dotted; */
}

.interactive-object-area.style3 .object-icon svg path {
  fill: #fff;
}

.interactive-object-area.style3 .object-text {
  color: #fff;
}

.interactive-object-area.style4 {
  border-color: #1f222e;
}

.interactive-object-area.style4 .interactive-object {
  background-color: #fff;
}

.interactive-object-area.style4 .object-icon svg circle {
  fill: #1f222e;
  stroke: #1f222e;
}

.interactive-object-area.style4 .object-icon svg path {
  fill: #fff;
}

.interactive-object-area.style4 .object-text {
  color: #1f222e;
}

.no-animation .object-icon {
  animation: none !important;
}

.interactive-object-area.style1 .object-icon {
  box-shadow: 0 0 0 rgba(253, 109, 135, 0.5);
  animation: style1 3s infinite;
  transform: translateZ(0);
  /* only needed if no CSS-3d commands are used to toggle GPU rendering */
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  backface-visibility: hidden;
  perspective: 1000;
}

@keyframes style1 {
  0% {
    box-shadow: 0 0 0 0 rgba(253, 109, 135, 0.8);
  }

  40% {
    box-shadow: 0 0 0 10px rgba(253, 109, 135, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(253, 109, 135, 0);
  }
}

.interactive-object-area.style2 .object-icon {
  box-shadow: 0 0 0 rgba(31, 25, 13, 0.5);
  animation: style2 2s infinite;
  transform: translateZ(0);
  /* only needed if no CSS-3d commands are used to toggle GPU rendering */
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  backface-visibility: hidden;
  perspective: 1000;
}

@keyframes style2 {
  0% {
    box-shadow: 0 0 0 0 rgba(200, 255, 0, 0.5);
  }

  50% {
    box-shadow: 0 0 0 5px rgba(2, 167, 124, 0.5);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

.interactive-object-area.style3 .object-icon {
  box-shadow: 0 0 0 rgba(222, 62, 91, 0.5);
  animation: style3 3s infinite;
  transform: translateZ(0);
  /* only needed if no CSS-3d commands are used to toggle GPU rendering */
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  backface-visibility: hidden;
  perspective: 1000;
}

@keyframes style3 {
  0% {
    box-shadow: 0 0 0 0 rgba(206, 33, 43, 0.9);
  }

  40% {
    box-shadow: 0 0 0 10px rgba(206, 33, 43, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(206, 33, 43, 0);
  }
}

.interactive-object-area.style4 .object-icon {
  box-shadow: 0 0 0 rgba(30, 34, 45, 0.5);
  animation: style4 3s infinite;
  transform: translateZ(0);
  /* only needed if no CSS-3d commands are used to toggle GPU rendering */
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  backface-visibility: hidden;
  perspective: 1000;
}

@keyframes style4 {
  0% {
    box-shadow: 0 0 0 0 rgba(30, 34, 45, 0.5);
  }

  40% {
    box-shadow: 0 0 0 10px rgba(30, 34, 45, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(30, 34, 45, 0);
  }
}

.mini .interactive-object {
  border: none !important;
}

.mini .object-text {
  display: none !important;
}

.midi .interactive-object {
  border: none !important;
}

.midi .object-text {
  display: none !important;
}

.interactive-object-area.style5 .object-icon-mini {
  box-shadow: 0 0 0 rgba(30, 34, 45, 0.5);
  animation: style5 3s infinite;
  transform: translateZ(0);
  /* only needed if no CSS-3d commands are used to toggle GPU rendering */
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  backface-visibility: hidden;
  perspective: 1000;
}

@keyframes style5 {
  0% {
    box-shadow: 0 0 0 0 rgba(253, 109, 135, 0.8);
  }

  40% {
    box-shadow: 0 0 0 10px rgba(253, 109, 135, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(253, 109, 135, 0);
  }
}

.text-tools {
  display: none;
  position: absolute;
  top: 350px;
  right: 10%;
  width: 254px;
  height: 34px;
  padding: 1px;
  background-color: var(--toolbar-bg);
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.text-tools.open {
  display: block;
}

.text-tools:after {
  /* arrow */
  content: "";
  position: absolute;
  bottom: -7px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: var(--toolbar-bg) transparent transparent transparent;
}

.text-tools .text-style,
.text-tools .text-size,
.text-tools .text-font-family {
  float: left;
}

.text-tools .text-size {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

.text-tools .button {
  height: 32px;
  width: 32px;
  border-radius: 3px !important;
  border-color: transparent;
  background-color: transparent;
  padding: 0;
  margin-right: 1px !important;
}

.text-tools .button img {
  width: 24px;
  height: 24px;
}

.text-tools .buttons .button {
  margin-bottom: 0;
}

.text-tools .button:hover {
  border-color: transparent;
  background-color: var(--icon-hover-bg);
}

.text-size select {
  width: 50px;
  height: 32px;
  line-height: 1;
  font-size: 14px;
  padding-bottom: calc(0.375em - 1px);
  padding-top: calc(0.375em - 1px);
  background: transparent;
  border: 0;
  border-radius: 3px;
  color: var(--text-color);
  outline: none;
}

.text-size .select:not(.is-multiple) {
  height: 2em;
}

.text-size .icon {
  position: absolute;
  right: 3px;
  top: 10px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  pointer-events: none;
}

.text-size .select select:not([multiple]) {
  padding-right: 6px;
}

.text-size .select:not(.is-multiple):after {
  content: none;
}

.text-font-family select {
  width: 100px;
  height: 32px;
  line-height: 1;
  font-size: 12px;
  padding-bottom: calc(0.375em - 1px);
  padding-top: calc(0.375em - 1px);
  background: transparent;
  border: 0;
  border-radius: 3px;
  color: var(--text-color);
  outline: none;
}

.text-font-family .select:not(.is-multiple) {
  height: 2em;
}

.text-font-family .icon {
  position: absolute;
  right: 3px;
  top: 10px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  pointer-events: none;
}

.text-font-family .select select:not([multiple]) {
  padding-right: 6px;
}

.text-font-family .select:not(.is-multiple):after {
  content: none;
}

.active.button {
  background-color: var(--icon-hover-bg) !important;
}

.active.button:active {
  background-color: transparent !important;
}

.page-number-bar-wrapper {
  position: fixed;
  width: 100%;
  height: 0;
  top: 0;
  z-index: 999;
}

.thumbnail-page-num {
  display: block;
  background-color: var(--toolbar-bg);
  text-align: center;
  width: 25px;
  position: relative;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid var(--border-color) !important;
  color: var(--text-color);
  font-size: 14px;
  font-weight: bold;
}

.page-number-bar {
  background: rgba(2, 0, 0, 0.7);
  width: 100px;
  margin: 25px auto;
  text-align: center;
  min-height: 35px;
  border-radius: 15px;
  padding: 10px;
  color: #caccce;
  font-size: 1.2em;
}

.toolbar {
  position: relative;
}

.toolbar-new {
  visibility: hidden;
}

.toolNavbar {
  position: relative;
  bottom: 15px;
  left: calc(50% - 200px);
  width: 150px;
}

.toolbar-draggable-handle {
  margin-right: -200px;
}

.toolbar-wrapper {
  position: fixed;
  bottom: 45vh;

  z-index: 999;
  /* Mustafa removed z-index*/
  left: calc(85% - 567px);
}

.navbar-wrapper {
  pointer-events: none;
  position: fixed;
  bottom: 15px;
  width: 120px;
  z-index: 9999;
  /* Mustafa removed z-index*/
  left: calc(50% - 200px);
}

.navbar-wrapper.hide {
  display: none;
}

.navbar-wrapper.open {
  display: block;
}

.toolbar-items {
  position: relative;
}

.main-toolbar {
  position: relative;
  width: auto;
  height: 46px;
  padding: 0;
  background-color: var(--toolbar-bg);
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.main-navbar {
  pointer-events: all;
  position: relative;
  width: auto;
  height: 44px;
  padding: 0;
  background-color: var(--toolbar-bg);
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.toolbar-items .buttons>ul {
  position: relative;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 3px 33px;
}

.toolbar-items .buttons>ul>li {
  display: inline-flex;
}

.toolbar-items .main-toolbar .buttons>ul>li:first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 46px;
  /*border-right: 1px solid var(--border-light-color);*/
}

.toolbar-items .main-navbar .buttons>ul>li:first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 46px;
  /*border-right: 1px solid var(--border-light-color);*/
}

.toolbar-items .main-toolbar .buttons>ul>li:last-child {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 46px;
  /*border-left: 1px solid var(--border-light-color)*/
}

.toolbar-items .main-tool .buttons>ul>li:last-child {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 46px;
  /*border-left: 1px solid var(--border-light-color)*/
}

.toolbar-items .main-toolbar-alternative .buttons>ul>li:last-child {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 46px;
  /*border-left: 1px solid var(--border-light-color)*/
}


.toolbar-items .main-toolbar-alternative .buttons>ul>li:first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 46px;
  /*border-right: 1px solid var(--border-light-color);*/
}

.alternative-drag-element .button {
  width: 20px !important;
  height: 54px !important;
  background-color: var(--icon-bg);
  margin: 0 !important;
  cursor: pointer;
  top: -4px !important;
}

.toolbar-items .main-toolbar .buttons>ul>li.drag-element .button {
  width: 30px;
  height: 46px !important;
  background-color: var(--icon-bg);
  margin: 0 !important;
  cursor: pointer;
  /* top: -4px !important; */
}

.toolbar-items .main-navbar .buttons>ul>li.drag-element .button {
  width: 30px;
  height: 46px;
  background-color: var(--icon-bg);
  margin: 0 !important;
  cursor: pointer;
}

.toolbar-items .main-toolbar .buttons>ul>li.drag-element .button img {
  opacity: 0.7;
}

.toolbar-items .main-navbar .buttons>ul>li.drag-element .button img {
  opacity: 0.7;
}

.toolbar-items .main-toolbar .buttons>ul>li:first-child .button {
  border-radius: 5px 0 0 5px;
}

.toolbar-items .main-navbar .buttons>ul>li:first-child .button {
  border-radius: 5px 0 0 5px;
}

.toolbar-items .main-toolbar .buttons>ul>li:last-child .button {
  border-radius: 0 5px 5px 0;
}

.toolbar-items .main-navbar .buttons>ul>li:last-child .button {
  border-radius: 0 5px 5px 0;
}

.toolbar-items .buttons.has-addons .button:last-child {
  margin: 0 1px;
}

.toolbar-items .button {
  height: 40px;
  width: 40px;
  border-radius: 3px;
  border-color: transparent;
  background-color: transparent;
  padding: 0;
}


.toolbar-items .button:hover {
  background-color: var(--icon-hover-bg);
  z-index: 2 !important;
}

.toolbar-items .buttons .button {
  margin-bottom: 0;
}

.toolbar-items .button img,
.toolbar-items .button svg {
  width: 20px;
  height: 20px;
}

.toolbar-items .button.main-button {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  border: 2px solid var(--tools-icon-in-border);
}

.toolbar-items .button.main-button:before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  left: -4px;
  top: -4px;
  border-radius: 50%;
  /* border: 2px solid var(--tools-icon-out-border); */
}

.toolbar-items .button.main-button img {
  width: 24px;
  height: 24px;
}

.toolbar-items .sub-menu .button:before {
  display: none;
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 5px 4px;
  border-color: transparent transparent var(--border-arrow-color) transparent;
}

.toolbar-items .sub-menu:hover .button:before {
  display: block;
}

.toolbar-items .draw-toolbar-icon .button:after {
  display: none;
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-radius: 0;
  border-style: solid;
  border-width: 0 4px 5px 4px;
  border-color: transparent transparent var(--border-arrow-color) transparent;
}

.toolbar-items .draw-toolbar-icon:hover .button:after {
  display: block;
}

.toolbar-items .draw-toolbar-icon.open:hover .button:after {
  display: none;
}

.toolbar-items .draw-toolbar-icon.open .main-button {
  background-color: var(--icon-hover-bg) !important;
}

.draw-toolbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 424px;
  /*width: 382px;*/
  height: 46px;
  margin: 0 auto;
  padding: 0;
  background-color: var(--toolbar-bg);
  border-radius: 5px 5px 0 0;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.draw-toolbar-zoom{
  /* margin-left: -80px !important; */
}

.draw-toolbar.open {
  opacity: 1;
  visibility: visible;
  bottom: 100%;
  transition: all 0.3s;
}

.draw-toolbar .buttons>ul {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 3px 2px;
}

.draw-toolbar .buttons>ul>li {
  position: relative;
}

.draw-toolbar .buttons>ul li ul:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-radius: 0;
  border-style: solid;
  border-width: 6px 5px 0 6px;
  border-color: var(--toolbar-bg) transparent transparent transparent;
}

.draw-toolbar .buttons>ul>li {
  display: inline-flex;
}



.draw-toolbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 424px;
  /*width: 382px;*/
  height: 46px;
  margin: 0 auto;
  padding: 0;
  background-color: var(--toolbar-bg);
  border-radius: 5px 5px 0 0;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.draw-toolbar.open {
  opacity: 1;
  bottom: 100%;
  visibility: visible;
  transition: all 0.3s;
}

.draw-toolbar .buttons>ul {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 3px 2px;
}

.draw-toolbar .buttons>ul>li {
  position: relative;
}

.draw-toolbar .buttons>ul li ul:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-radius: 0;
  border-style: solid;
  border-width: 6px 5px 0 6px;
  border-color: var(--toolbar-bg) transparent transparent transparent;
}

.draw-toolbar .buttons>ul>li {
  display: inline-flex;
}

/*Page Options*/

.page-options {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 250px;
  /*width: 382px;*/
  height: 46px;
  margin: 0 auto;
  padding: 0;
  background-color: var(--toolbar-bg);
  border-radius: 5px 5px 0 0;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
}

.page-options2 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 214px;
  /*width: 382px;*/
  height: 46px;
  margin: 0 auto;
  padding: 0;
  background-color: var(--toolbar-bg);
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
}

.page-options2 ul {
  padding: 1px !important;
}

.page-options.open {
  opacity: 1;
  bottom: 100%;
  transition: all 0.3s;
}

.page-options2.open {
  opacity: 1;
  bottom: 100%;
  transition: all 0.3s;
}

.toolbar.vertical .page-options2.open {
  bottom: -100%;
  border-radius: 0 0 5px 5px;
}

.toolbar.vertical .alternative-openable-menu .left-side.activity-options.open {
  bottom: -46% !important;
  border-radius: 0 0 5px 5px;
}

.toolbar.vertical .alternative-openable-menu .left-side.draw-toolbar.open {
  bottom: -46% !important;
  border-radius: 0 0 5px 5px;
}

.toolbar.vertical .alternative-openable-menu .right-side.activity-options.open {
  bottom: -46% !important;
  border-radius: 0 0 5px 5px;
}

.toolbar.vertical .alternative-openable-menu .right-side.draw-toolbar.open {
  bottom: 100% !important;
  border-radius: 5px 5px 0px 0px;

}

.page-options .buttons>ul>li {
  position: relative;
}

.page-options .buttons>ul li ul:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-radius: 0;
  border-style: solid;
  border-width: 6px 5px 0 6px;
  border-color: var(--toolbar-bg) transparent transparent transparent;
}

.page-options .buttons>ul>li {
  display: inline-flex;
}

/* SUB MENU */

.sub-menu {
  position: relative;
}

.sub-menu>div {
  display: none;
  position: absolute;
  bottom: 100%;
  width: 200px;
  min-height: 110px;
  left: 50%;
  margin-left: -100px;
  padding: 15px 10px;
  font-size: 0.8rem;
  color: var(--text-color);
  background-color: var(--toolbar-bg);
  border: 1px solid var(--border-light-color);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  z-index: 3;
  opacity: 1;
}

.buttons.has-addons .sub-menu .button:not(:last-child) {
  border-radius: 3px;
  margin: 0 1px;
}

.sub-menu.open:before {
  content: "";
  position: absolute;
  bottom: 100%;
  top: 5px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: var(--border-light-color) transparent transparent transparent;
  z-index: 4;
}

.sub-menu.open:after {
  content: "";
  position: absolute;
  bottom: 100%;
  top: 2px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: var(--toolbar-bg) transparent transparent transparent;
  z-index: 5;
}

.sub-menu.open>div {
  display: block;
}

.sub-menu.open .button:before {
  content: none;
}

.sub-menu-title {
  display: block;
  font-weight: 600;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.user-info {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.user-photo {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.user-photo figure {
  margin-left: 0;
  margin-right: 0.5rem;
  border: 1px solid var(--border-light-color);
  border-radius: 50%;
}

.user-title {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  line-height: 0.9rem;
  font-weight: 500;
}

.user-name {
  display: block;
  margin-bottom: 2px;
  color: var(--text-hover-color);
}

.user-work {
  display: block;
  font-size: 12px;
  font-weight: 300;
  color: var(--text-color);
}

.user-menu-buttons {
  text-align: center;
  margin-bottom: 15px;
}

.user-menu-buttons .field.is-grouped>.control:not(:last-child) {
  margin-right: 0.5rem;
}

.toolbar-items .buttons.has-addons .user-menu .button:last-child {
  margin: 0;
}

.user-menu-buttons .button {
  height: 32px;
  width: 85px;
  border-radius: 3px;
  border-color: transparent;
  background-color: var(--other-button-bg);
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  color: var(--text-color);
}

.user-menu-buttons .button:hover {
  background-color: var(--other-button-hover-bg);
  color: var(--text-hover-color);
}

.theme-selection label {
  display: inline-block;
  font-size: 12px;
  padding-top: 7px;
  padding-left: 3px;
}

.theme-selection .select-area {
  text-align: left;
  margin-top: 0;
}

.theme-selection .select {
  float: right;
}

.theme-selection .select:not(.is-multiple) {
  height: auto;
}

.theme-selection .select select {
  height: 32px;
  width: 85px;
  border-radius: 3px;
  border-color: transparent;
  background-color: var(--other-button-bg);
  padding: 0 0 0 6px;
  font-size: 12px;
  font-weight: normal;
  color: var(--text-color);
}

.theme-selection .select select:hover {
  background-color: var(--other-button-hover-bg);
  color: var(--text-hover-color);
}

.theme-selection .select-area .icon {
  top: 9px;
}

/* Draw Toolbar Sub Menu */

.draw-toolbar .sub-menu>div {
  width: 48px;
  min-height: 48px;
  margin-left: -23px;
  padding: 3px 2px;
}

.draw-toolbar .buttons>ul ul {
  padding: 0;
  margin: 0;
}

.draw-toolbar .buttons:last-child {
  margin: 0;
}

.toolbar-items .draw-toolbar .line-menu .button img,
.toolbar-items .draw-toolbar .line-menu .button svg {
  width: auto;
  height: auto;
}

/* Min Toolbar */

.toolbar.min-toolbar {
  display: block;
  margin-left: 365px;
  /* BURASI NEDEN BOYLE MST? */
}

.toolbar.min-toolbar .sub-menu.open,
.toolbar.min-toolbar .button.normal-btn {
  display: none;
  visibility: hidden;
}

.toolbar.min-toolbar .toolbar-items .buttons>ul {
  padding: 3px 33px 3px 3px;
}

.toolbar.min-toolbar .toolbar-items .main-toolbar .buttons .toolbar-pin {
  display: inline-flex;
  visibility: visible;
  transform: scale(-1, 1);
}

.toolNavbar.min-toolbar .toolbar-items .main-navbar .buttons .toolbar-pin {
  display: inline-flex;
  visibility: visible;
  transform: scale(-1, 1);
}

.toolbar.min-toolbar .toolbar-items .main-toolbar .buttons>ul>li:first-child {
  display: none;
  visibility: hidden;
}

.toolNavbar.min-toolbar .toolbar-items .main-navbar .buttons>ul>li:first-child {
  display: none;
  visibility: hidden;
}

.toolbar.min-toolbar .draw-toolbar {
  display: none;
  visibility: hidden;
}

.toolbar.min-toolbar .page-options2 {
  display: none;
  visibility: hidden;
}

/* Vertical Toolbar*/

.toolbar.vertical {
  transform: rotate(90deg);
  transform-origin: 50%;
  bottom: 0;
  left: auto;
  visibility: visible;
}

.toolbar.horizontal {

  visibility: visible;
  left: 50% - 567px !important;
  /* transform-origin: right 50% 0; */
}

.toolbarsize-1-5x-h {
  transform: scale(1.5);
}

.toolbarsize-2x-h {
  transform: scale(2);
}

.toolbarsize-1-5x-v {
  transform: rotate(90deg) scale(1.5) !important;
}

.toolbarsize-2x-v {
  transform: rotate(90deg) scale(2) !important;
}

.horizontal1 {
  left: 40% !important;
  bottom: 2vw !important;
}

.vertical1 {
  /* transform-origin: 50%; */
  /* left: calc(left-180px); */
}

.toolbar.vertical .button.normal-btn {
  transform: rotate(-90deg);
}

.toolbar.vertical .sub-menu .button:before {
  top: 0;
  left: 0;
  right: auto;
  bottom: 0;
  transform: rotate(-90deg);
}

.toolbar.vertical .draw-toolbar-icon:hover .button:after {
  top: 0;
  left: -1px;
  right: auto;
  bottom: 0;
  transform: rotate(-90deg);
}

.toolbar.vertical .draw-toolbar.open {
  bottom: -100%;
  border-radius: 0 0 5px 5px;
}


.toolbar.vertical.min-toolbar {
  right: 45px;
  bottom: 0;
}

.toolbar.vertical .sub-menu>div {
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  transform-origin: right;
  bottom: auto;
  top: 100%;
}

.toolbar.vertical .button.vertical-toolbar {
  transform: rotate(180deg) scaleX(-1);
}

.toolbar.vertical .sub-menu.open:before,
.toolbar.vertical .sub-menu.open:after {
  transform: rotate(-180deg);
  top: 100%;
}

.toolbar.vertical .sub-menu.open:before {
  bottom: 6px;
}

.toolbar.vertical .sub-menu.open:after {
  bottom: 2px;
}

.toolbar.vertical .draw-toolbar .sub-menu .button.normal-btn {
  transform: rotate(-90deg);
}

.toolbar.vertical .draw-toolbar .sub-menu ul li .button.normal-btn {
  transform: rotate(0deg);
}

/* Filter icons */

.sidebar-footer .icon img,
.searchbar-footer .icon img,
.toolbar .button img,
.text-tools .button img,
.text-tools .icon img,
.theme-selection .icon img {
  filter: var(--icons-filter);
}

.preloader-mini-frame {
  position: absolute;
  display: block;
  padding: 0px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.preloader-mini-icn {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  opacity: 0.9;
  width: 16px;
  height: 16px;
  -webkit-animation: clockwise 500ms linear infinite;
  animation: clockwise 500ms linear infinite;
}

.preloader-mini-cut {
  position: relative;
  width: 8px;
  height: 16px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.preloader-mini-donut {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-radius: 50%;
  border-left-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  margin: 0;
  animation: donut-rotate 1000ms cubic-bezier(0.4, 0, 0.22, 1) infinite;
}

@keyframes clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes donut-rotate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-140deg);
  }

  100% {
    transform: rotate(0);
  }
}

/* LOGIN PAGE */

.login-form-area {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--body-bg);

}

.login-form {
  display: flex;
  width: 660px;
  height: 400px;
  background: transparent;
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
}

.lf-left {
  width: 330px;
  height: 450px;
  padding: 50px 50px;
  background: var(--sidebar-bg);
  border-radius: 20px 0 0 20px;
}

.welcome-logo {
  /* width: 50%; */
  padding-bottom: 50px;
  /* padding-left: 10px; */
}

.welcome-logo-company {
  /* width: 50%; */
  padding-bottom: 20px;
  padding-left: 10px;
}

.lf-right {
  width: 330px;
  height: 450px;
  padding: 60px 50px;
  background: #f7f7f7;
  border-radius: 0 20px 20px 0;
}

.lf-left-title {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 25px;
}

.lf-left-title span {
  border-bottom: 3px solid #fff;
}

.lf-left-text {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 1.2;
  margin-bottom: 25px;
}

.lf-left-notice {
  font-size: 12px;
  font-weight: 400;
  color: #7a828b;
  line-height: 1.2;
}

.lf-right-title {
  font-size: 30px;
  color: #2b2c2f;
  text-align: center;
  margin-bottom: 25px;
}

.validate-input {
  position: relative;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 25px;
}

.validate-input:before {
  display: none;
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: transparent;
  border: 0;
  border-radius: 3px;
  padding: 0;
  right: auto;
  bottom: -14px;
  top: auto;
  pointer-events: none;
  color: #c80000;
  font-size: 10px;
  line-height: 1;
  text-align: left;
  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.login-form-label {
  display: block;
  font-size: 10px;
  font-weight: 600;
  color: #494e54;
}

.login-form-input {
  font-size: 14px;
  font-weight: 600;
  color: #2b2c2f;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 36px;
  background: transparent;
  padding: 6px 0 0;
  outline: none;
  border: none;
}

.login-form-input::placeholder {
  color: #caccce;
  font-weight: normal;
}

.login-form-input[type="password"] {
  letter-spacing: 0.01em;
}

.login-form-input[type="password"]::placeholder {
  letter-spacing: normal;
}

.login-form-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 100px;
  height: 30px;
  margin: 0 auto;
  border-radius: 5px;
  border: 0;
  background: var(--sidebar-bg);
  color: #eee;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
}

.login-form-btn:hover {
  background: #2b2c2f;
  color: #fff;
}

/* PRELOADER FULL*/
.preloader-area {
  position: relative;
  height: 90vh;
}

.preloader-frame {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.preloader-text {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  opacity: 0.9;
  letter-spacing: 3px;
  text-indent: 3px;
  text-align: center;
  margin-top: 5px;
}

.preloader-circle {
  width: 50px;
  height: 50px;
  padding: 0px;
  margin: 0 auto;
}

.preloader-circle span {
  position: absolute;
  display: block;
  left: 50%;
  margin-left: -25px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #fff;
  animation: preloader-circle 1.4s linear infinite;
}

.preloader-circle.is-loading-anim span {
  position: absolute;
  background: var(--icon-color) !important;
  z-index: 2;
  margin: auto;
  width: 50px;
  height: 50px;
  left: unset;

  /* opacity: .1; */

}
.is-loading-anim{
  margin: auto;
  right:1rem;
  /* left:; */
  top:0px;
  position: absolute;
}


.preloader-circle span:last-child {
  animation-delay: -0.9s;
}

.preloader-circle span:first-child {
  animation-delay: -0.5s;
}

@keyframes preloader-circle {
  0% {
    transform: scale(0, 0);
    opacity: 0.95;
  }

  50% {
    transform: scale(0.5, 0.5);
    opacity: 0.5;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}








/* Custom Classes */

.hide-element {
  display: none;
}

.line-color-picker {
  margin-left: 45%;
  margin-bottom: 15%;

}

.fill-color-picker {
  margin-left: 55%;
  margin-bottom: 15%;
}

.verticalOn {
  /* margin-left: 0% !important; */
  margin-left: -95px !important;
  visibility: visible;
}

.verticalOff {
  visibility: visible;
}

button.disable-button:hover {
  background-color: var(--sidebar-bg);
  cursor: not-allowed;
}

.interactive-video-player {
  width: 100%;
}

.interactive-video-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: auto;
  min-height: 400px;
  overflow: hidden;
}

.interactive-h5p-player {
  width: 100%;
  height: 100% !important;
  box-sizing: border-box !important;
  /* height: 70vh !important; */
}

.interactive-h5p-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  /* height: 69vh !important;

  min-height: 500px !important; */
  /* overflow: hidden; */
  padding-left: 10px;
  padding-top: 35px;
  /* padding: 0 !important; */
  box-sizing: border-box !important;
  background-color: rgba(255, 255, 255, 0) !important;
}


.divCentered {
  position: absolute;
  width: 300px;
  height: 200px;

  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
}


.audioPlayer {
  padding: 0px 6px 0px 6px;
}

.interactive-link-player {
  width: 100%;
}

.interactive-link-player iframe {
  position: absolute;
  /* top: 0; */
  left: 0;
  width: 100% !important;
  height: 100% !important;
  min-height: auto;
  overflow: hidden;
}

.modal>.modal-dialog {
  max-width: 80vw !important;
}


.loadingText {
  text-align: center;
  vertical-align: middle;
  color: #7a828b;
  font-size: 18px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 40px;
}

.modal-theme {
  width: 85%;
  max-width: 100% !important;
  pointer-events: none;
  z-index: 1000;
}

.modal-theme>.modal-content {
  border: 1px solid white;
  width: 80%;
  padding: 10px;
  display: table;
  font-size: 24px !important;
  /* background-color: rgba(255, 255, 255, 0.9); */
  background-color: var(--body-bg) !important;
}

.modal-theme>.modal-card {
  width: 100%;
  max-width: 90%;

}

.modal-theme-auto {
  width: auto !important;
  min-width: 300px !important;
  /* max-width: 100% !important; */
  pointer-events: none;
  z-index: 1000;
}

.modal-theme-auto>.modal-content {
  min-width: 300px !important;
  border: 1px solid var(--text-hover-color);
  width: auto !important;
  padding: 10px;
  display: table;
  font-size: 24px !important;
  background-color: var(--body-bg) !important;
}

.modal-theme-auto>.modal-card {
  max-width: 90% !important;
  width: auto !important;
}

.modal-theme-auto>.modal-dialog {
  max-width: 90% !important;
  width: auto !important;
}

.zoomPage3 {
  height: 94vh;
  transform: scale(3);
  -ms-transform: scale(3);
  /* IE 9 */
  -webkit-transform: scale(3);
  transform-origin: 50% 0;
  -ms-transform-origin: 0 0;
  /* IE 9 */
  -webkit-transform-origin: 0 0;
}

.zoomPage2-75 {
  height: 94vh;
  transform: scale(2.75);
  -ms-transform: scale(2.75);
  /* IE 9 */
  -webkit-transform: scale(2.75);
  transform-origin: 50% 0;
  -ms-transform-origin: 0 0;
  /* IE 9 */
  -webkit-transform-origin: 0 0;
}

.zoomPage2-5 {
  height: 94vh;
  transform: scale(2.5);
  -ms-transform: scale(2.5);
  /* IE 9 */
  -webkit-transform: scale(2.5);
  transform-origin: 50% 0;
  -ms-transform-origin: 0 0;
  /* IE 9 */
  -webkit-transform-origin: 0 0;
}

.zoomPage2-25 {
  height: 94vh;
  transform: scale(2.25);
  -ms-transform: scale(2.25);
  /* IE 9 */
  -webkit-transform: scale(2.25);
  transform-origin: 50% 0;
  -ms-transform-origin: 0 0;
  /* IE 9 */
  -webkit-transform-origin: 0 0;
}

.zoomPage2 {
  height: 94vh;
  transform: scale(2);
  -ms-transform: scale(2);
  /* IE 9 */
  -webkit-transform: scale(2);
  transform-origin: 50% 0;
  -ms-transform-origin: 0 0;
  /* IE 9 */
  -webkit-transform-origin: 0 0;
}

.zoomPage1-75 {
  height: 94vh;
  transform: scale(1.75);
  -ms-transform: scale(1.75);
  /* IE 9 */
  -webkit-transform: scale(1.75);
  transform-origin: 50% 0;
  -ms-transform-origin: 0 0;
  /* IE 9 */
  -webkit-transform-origin: 0 0;
}

.zoomPage1-5 {
  height: 94vh;
  transform: scale(1.5);
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  transform-origin: 50% 0;
  -ms-transform-origin: 0 0;
  /* IE 9 */
  -webkit-transform-origin: 0 0;
}

.zoomPage1-25 {
  height: 94vh;
  transform: scale(1.25);
  -ms-transform: scale(1.25);
  /* IE 9 */
  -webkit-transform: scale(1.25);
  transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  /* IE 9 */
  -webkit-transform-origin: 0 0;
}

.zoomPage1 {
  height: 94vh;
  transform: scale(1);

  -ms-transform: scale(1);
  /* IE 9 */
  -webkit-transform: scale(1);
  transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  /* IE 9 */
  -webkit-transform-origin: 0 0;
}

.zoomPage0-75 {
  height: 94vh;
  transform: scale(0.75);
  -ms-transform: scale(0.75);
  /* IE 9 */
  -webkit-transform: scale(0.75);
  transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  /* IE 9 */
  -webkit-transform-origin: 50% 0;
}

.zoomPage0-5 {
  height: 94vh;
  transform: scale(0.5);
  -ms-transform: scale(0.5);
  /* IE 9 */
  -webkit-transform: scale(0.5);
  transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  /* IE 9 */
  -webkit-transform-origin: 50% 0;
}

.zoomPage0-4 {
  height: 94vh;
  transform: scale(0.4);
  -ms-transform: scale(0.4);
  /* IE 9 */
  -webkit-transform: scale(0.4);
  transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  /* IE 9 */
  -webkit-transform-origin: 50% 0;
}

.zoomPage0-3 {
  height: 94vh;
  transform: scale(0.3);
  -ms-transform: scale(0.3);
  /* IE 9 */
  -webkit-transform: scale(0.3);
  transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  /* IE 9 */
  -webkit-transform-origin: 50% 0;
}

.modal-theme .modal-body {
  width: auto;
  background-color: #fff;
}

.modal-theme-auto .modal-body {
  width: auto;
  background-color: #fff;
}

.customText {
  font-size: 20px !important;
}



.navbar {
  /* display: none; */
}

.nav-drag {
  margin: 40px 0px 0px 0px !important;
}



.dOff {
  /* display: none; */
}

.modal-title-narrow {
  height: 10px;
}

button.close {
  margin: 0px;
  padding: 0px !important;
}

.close span {
  color: white;
  margin: 0px;
}

div.modal-header {
  border: none;
  color: white;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0);
  display: none;
}

.non-margin {
  margin: 0px;
  padding: 0px;
}

div.fade {
  pointer-events: none;
}

.pagination {
  background-color: white;
  display: inline-block;
  margin: 0px 0px 3px 18px;
}

.pagination p {
  color: black;
  float: left;
  padding: 3px 8px;
  text-decoration: none;
  cursor: pointer;
}

.selectedPagination {
  background-color: var(--sidebar-footer-bg);
  color: var(--text-color) !important;
}

.modal.fade .modal-dialog {
  transition: unset !important;
}

.index-icon {
  display: inline-block;
  width: 32px;
  height: 28px;
  padding: 2px 2px 2px 3px;
  margin: 3px 0px 0px 25px;
  border-radius: 3px;
  border: 1px solid var(--border-color);
  outline: none;
  cursor: pointer;
  background-color: transparent;
}


.modal-title h2 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  border-bottom: 2px solid #2B2C2F;
  padding-bottom: 12px;

}

.modal-title-icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 5px;

  vertical-align: middle;
}

.modal-title-icon img {
  width: 22px;
  height: 22px;
}

.modal-title-text {
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;

}

.modal-title-text-small {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px !important;
  margin-right: 2px;
}

.activity-span {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px !important;
  width: 150px;
}

.modal-left {
  position: relative;
  width: 250px;
  background-color: #F0F0F2;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}

.modal-left .tabs ul {
  display: block;
  position: absolute;
  left: 10px;
  right: 0;
  bottom: 10px;
}

.modal-left .tabs a {
  justify-content: left;
  border-bottom: 0;
  border-radius: 3px 0 0 3px;
  margin-bottom: 1px;
  margin-right: -1px;
  padding: 6px 8px;

}

.modal-left .tabs a:hover {
  background-color: #FFF;
}

.modal-left .tabs-icon {
  width: 22px;
  height: 22px;
  margin-right: 6px;
}

.modal-left .tabs-icon svg {
  width: 22px;
  height: 22px;
}

.modal-left .tabs li.active a {
  color: #02b31a;
  background-color: #FFF;
  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.05);
}

.modal-left .tabs li.active a svg path,
.modal-left .tabs li.active a svg circle {
  fill: #63d858;
}

.modal-right {
  overflow-y: auto;
  padding-left: 25px;
  padding-right: 8px;
  height: 500px;
}

.modal-right-content {
  display: none;
}

.modal-right-content.active {
  display: block;
}

.modal-right a {
  color: #2B2C2F;
}

.modal-right a:hover {
  color: #40d107;
}

.modal-right-content>ul {
  font-size: 18px;
  font-weight: bold;
}

.modal-right-content>ul>li {
  margin-bottom: 30px;
}

.modal-right-content>ul>li:last-child {
  margin-bottom: 0;
}

.modal-right-content li {
  line-height: 1.4em;
  position: relative;
  margin-bottom: 10px;
}

.modal-right-content li a {
  display: block;
  margin-bottom: 10px;
  padding-right: 10px;
}

.modal-right-content>ul>li>a {
  display: block;
  border-bottom: 1px solid #2B2C2F;
}

.modal-right-content>ul ol {
  font-size: 16px;
  font-weight: bold;
  margin-left: 20px;
}

.modal-right-content>ul ol ol {
  font-size: 16px;
  font-weight: normal;
  list-style: lower-alpha;
}

.modal-right-content .page-number {
  position: absolute;
  right: 5px;
  top: 1px;
  font-size: 16px;
  font-style: italic;
  color: #4A5067;
}

.modal-right-content>ol {
  margin-left: 20px;
}

.bookmark {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 4vw;
  height: 4vW;
  border: 1px solid var(--body-bg);
  border-radius: 0px 0px 50px 50px;
  padding: .6vw;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  outline: none !important;
  background-color: var(--body-bg);
}

.bookmark-svg-container {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 90%;
}

.bookmark-svg {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.bookmark-svg path {
  stroke: var(--icon-color);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.bookmark.active,
.bookmark:hover {
  /* border-color: var(--notificationError); */
  /* border-color: #E54D42; */
}

.bookmark.active svg path {
  fill: var(--notificationError);
  stroke: var(--notificationError);
}

.activities {
  display: inline-block;
}

.activities.img svg path {
  fill: rgb(13, 226, 30);
  stroke: rgb(87, 212, 4);
}

.merriamComponent {
  color: white;
  margin-left: 10px;
  font-size: 13px;
}

.soundIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.moreMerriamWord {
  color: rgba(0, 162, 255, 0.966);
  cursor: pointer;

}

.merriamMoreIcon {
  width: 40px;
  height: 40px;
}


/*/////////////////////*/


*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  padding: 0;
  overflow: visible;
  overscroll-behavior: contain;
}

h1,
p {
  font-family: Lato;
}

.crosshairs {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 2147483645;
}

.crosshairs.hidden {
  display: none;
}


.crosshairs::before,
.crosshairs::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  border: none !important;
}

.crosshairs::before {
  left: -100%;
  top: -100%;
  border-right: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.crosshairs::after {
  left: 0px;
  top: 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

}

.overlay.highlighting {
  background: none;
  border-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
}

.text-center>.btn {

  background-color: var(--icon-hover-bg);
  z-index: 2 !important;
}

.green_capture_view {
  background-color: #355f5c;
}

._capture_view {
  background-color: #45484E;
}

.light_capture_view {
  background-color: #E5E5E5;
}

.dark-blue_capture_view {
  background-color: #1C3549;
}


.svg-small {
  height: 24px;
  margin-left: 2px;
}

.passive {
  /* visibility: hidden; */
  opacity: .25;
  pointer-events: none !important;
  cursor: not-allowed;

}

/* Library */
.library {
  min-height: 100vh;
}

.library-content {
  padding-top: 130px;
}

.library-header {
  height: 90px;
  background-color: var(--sidebar-bg);
  color: var(--text-color);
  margin-bottom: 40px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, .15);
  min-width: 320px;
}

.library-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.library-header h1 {
  font-size: 32px;
  line-height: 1;
}

.welcome-user {
  width: auto;
  text-align: right;
  line-height: 1;
  padding-top: 8px;
  text-transform: capitalize;
}

.welcome-user a {
  display: inline-block;
  color: #CCC;
  text-decoration: none;
}

.welcome-user a:hover {
  color: #FFF;
  text-decoration: underline;
}

.library-content-row {
  margin-bottom: 30px;
}

.library-content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
  border-bottom: 1px solid #E5E5E5;
}

.library-content-title h2 {
  font-size: 22px;
}

.library-content-title .dropdown button {
  border-width: 0;
}

.library-content-title .dropdown button:hover {
  border-width: 0;
}

.library-book-list li {
  display: inline-block;
  width: 150px;
  margin-right: 30px;
  margin-bottom: 30px;
  vertical-align: top;
}

.library-book-item {
  transition: all .25s;
}

.library-book-item:hover {
  transform: scale(1.02);
}

.library-book-cover {
  position: relative;
  display: block;
  width: 150px;
  height: 200px;
  overflow: hidden;
  margin-bottom: 15px;
  border-radius: 3px;
}

.library-book-cover:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, .025);
}

.library-book-cover img {
  width: 100%;
  height: auto;
}

.library-book-title {
  display: block;
  width: 150px;
  font-size: 13px;
  color: #2B2C2F;
  height: 48px;
  letter-spacing: -.5px;
}

.library-book-item:hover .library-book-title {
  text-decoration: underline;
}

.capitalize {
  text-transform: capitalize;
}

.library-selection label {
  display: inline-block;
  font-size: 12px;
  padding-top: 7px;
  padding-left: 3px;
}

.library-selection .select-area {
  text-align: left;
  margin-top: 0;
  padding-left: 18px;
  padding-top: 18px;
}

.library-selection .select {
  float: right;
  align-items: center;
}

.library-selection .select:not(.is-multiple) {
  height: auto;
}

.library-selection .select select {
  height: 32px;
  width: 45px;
  border-radius: 3px;
  border-color: transparent;
  background-color: rgba(0, 0, 0, .025);
  padding: 0 0 0 6px;
  font-size: 16px;
  font-weight: normal;
  color: var(--text-color);
}

.library-selection .select select:hover {
  background-color: var(--other-button-bg);
  color: var(--text-hover-color);
}

.library-selection .select-area .icon {
  top: 9px;
}

.introjs-helperNumberLayer {
  display: none;
}

.introjs-button {
  font-weight: 600 !important;
  font-size: large !important;
}


/* Library End */


/* Form for add button */
.main-model {
  width: 100%;
  max-width: 800px;
  margin: auto;
  display: table;
  padding: 15px;
  font-family: arial;
  overflow: scroll;
  height: 300px;
  max-height: 300px;
}

.label {
  /* TODO CHANGE THIS LABEL */
  width: 100%;
  float: left;
  font-size: 15px;
  color: #000000;
  margin: 0 0 7px;
  text-transform: capitalize;
  font-weight: bold;
}

.error-message {
  width: 100%;
  float: left;
  font-size: 14px;
  color: #CB0003;
}

.w-100 {
  width: 100%;
  float: left;
  margin: 15px 0 7px;
}

.new-text-area {
  width: 100%;
  float: left;
  height: 120px;
  resize: none;
  outline: none;
  border: solid 1px #414141;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px 10px;
  font-size: 14px;
  color: #000000;
}

.new-text-filed {
  color: #4a4a4a;
  width: 100%;
  float: left;
  /*height: 40px;*/
  font-size: 14px;
  resize: none;
  outline: none;
  border: solid 1px #dbdbdb;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px 10px;
}

.box-left {
  width: 49%;
  float: left;
}

.box-right {
  width: 49%;
  float: right;
}

.submit-btn {
  width: auto;
  height: 40px;
  background-color: var(--notificationInfo);
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
  border: none;
  padding: 3px 15px;
  border-radius: 5px;
  margin: 15px 0 0;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #555555;
}

.cancel-btn {
  width: auto;
  height: 40px;
  background-color: var(--notificationError);
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
  border: none;
  padding: 3px 15px;
  border-radius: 5px;
  margin: 15px 0 0 10px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #555555;
}



.Toastify__toast--dark {
  background-color: var(--active-object-bg);
}

.Toastify__progress-bar {
  background-color: var(--body-bg);
}


.Toastify__toast--default {
  background-color: var(--active-object-bg);
}

.Toastify__toast--info {
  background-color: var(--notificationInfo);
}

.Toastify__toast--success {
  background-color: var(--notificationSuccess);
}

.Toastify__toast--warning {
  background-color: var(--notificationWarning);
  color: #1D1E20;
}

.Toastify__toast--error {
  background-color: var(--notificationError);
}

/* .Toastify__toast-body {
} */

.form-style-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-color: #1D1E20;
  border-width: 5px;
  width: 100%
}

.form-radio-label {
  font-size: 1rem !important;
}

.no-internet {
  color: red;
  font-weight: bold;
}

.no-internet-login {

  color: red;
  font-weight: bold;
  position: sticky;
  top: 30px;
}

.no-internet-ebook {
  color: red;
  font-weight: bold;
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: 999; */
  width: 100%;
  height: 23px;
  text-align: center;
  font-size: small;
  margin-top: 5px;
  /* padding-right: 50px; */
}

.question-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  height: calc(100vh - 186px);
}

.answer-options label {
  background: #FFFFFF;
  border: 1px solid #DDDEE8;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.005em;
  color: #333555;
  /* height: 40px; */
  display: flex;
  align-items: center;
  padding: 5px 10px;
  position: relative;
  cursor: pointer;
  max-width: 98%;
}

.answer-options label {
  margin: 10px;
}

.answer-options label .mcq-option {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: -0.005em;
  color: #333555;
  margin-right: 10px;
  flex-shrink: 0;
}

.answer-options label input {
  position: absolute;
  opacity: 0;
  max-width: 0;
  max-height: 0;
  visibility: hidden;
}

.result-page .answer-options label {
  cursor: not-allowed;
}

.answer-options label p {
  line-height: 1.2em;
  font-size: 1.2rem;
}

.question-text p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.005em;
  color: #000000;
  margin-bottom: 25px;
  font-family: "Nunito Sans";
}

.question-text p span {
  font-size: unset;
}

.ql-formula {
  font-size: 1.5em !important;
}

.question-text h4 {
  font-size: 16px;
  margin-bottom: 26px;
}

.single-question {
  display: flex;
}

.question-sl {
  flex: 0 0 40px;
  text-align: center;
  padding-right: 28px;
}

.question-sl .question-number {
  display: block;
  text-align: center;
  margin-bottom: 15px;
  width: 30px;
  letter-spacing: -0.075em;
  position: relative;
  cursor: pointer;
}

.result-page .question-sl img {
  cursor: not-allowed !important;
}

.question-text-wrap {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  font-family: "Nunito Sans" !important;
}

.answer-options label.active {
  background: #DDDEE8;
  border: 1px solid #888999;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(51, 53, 85, 0.15);
}

.answer-options label.active-true {
  border: 3px solid var(--notificationSuccess);
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(51, 53, 85, 0.15);
}

.answer-options label.active-false {
  border: 3px solid var(--notificationError);
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(230, 100, 13, 0.15);
}

.answer-options label.active-solution {
  border: 3px solid #2af32a;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(7, 233, 37, 0.15);
}

.answer-options label.active .mcq-option {
  background: #333555;
  border: 1px solid #DDDEE8;
  color: #fff;
}

.answer-options {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex: 1;

  flex-direction: row;
}



.mcq-option {
  background: #FFFFFF;
  border: 1px solid #DDDEE8;
  box-sizing: border-box;
  border-radius: 12px;
  width: 22px;
  height: 22px;
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.005em;
  color: #333555;
}

.box-btn {
  background: #FFAA40;
  border-radius: 10px;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.005em;
  color: #FFFFFF;
  border: 0;
  padding: 10px 15px;
  transition: all .3s;
  margin-left: 20px;
}

.box-btn:hover {
  filter: brightness(0.9);
}

.box-btn.style-2 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.005em;
  color: #333555;
  background: #21CF9A;
  transition: .3s;
}

.box-btn.style-2.white {
  color: #333555;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 15px;
  height: 42px;
  border: 1px solid #dddee8;
  -webkit-box-shadow: 0px -1px 5px rgba(51, 53, 85, 0.1);
  box-shadow: 0px -1px 5px rgba(51, 53, 85, 0.1);
}

.box-btn.style-2.red {
  background: #DC3545;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 15px;
  height: 42px;
}

.box-btn.style-2.red:hover {
  background: #333555;
}

.twin-btn-wrap {
  text-align: center;
  margin-top: auto;
}

.twin-btn-wrap a {
  color: #888999;
  margin: 0 5px;
  position: relative;
}

.twin-btn-wrap a.box-btn.style-2 {
  line-height: auto !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.twin-btn-wrap-disabled {
  cursor: not-allowed;
  background: #dddee8 !important;
  border: 1px solid #acacb6 !important;
  color: #acacb6 !important;
}

.twin-btn-wrap-disabled:hover {
  cursor: not-allowed;
  background: #dddee8 !important;
  border: 1px solid #acacb6 !important;
  color: #acacb6 !important;
  filter: brightness(1);
}

.twin-btn-wrap-enabled {
  cursor: pointer;
  border: 1px solid #888999;
  background: transparent !important;
  color: #333555;
}

.twin-btn-wrap-enabled:hover {
  border: 1px solid #21CF9A;
  background: #21CF9A !important;
  color: #FFF !important;
  /* Mustafa changed */
}

.exam-bottom {
  left: 20px;
  width: auto;
  text-align: right;
  margin-top: 20px;
}

.feedback-positive {


  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.005em;
  color: white;
  margin-top: 25px;
  font-family: "Nunito Sans";
  background-color: var(--notificationSuccess);
  padding: 10px;
}

.feedback-negative {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.005em;
  background-color: var(--notificationError);
  color: #ffffff;
  margin-top: 25px;
  padding: 10px;
  font-family: "Nunito Sans";
}

.quill-container {
  height: 200px;
}

.quill-root {
  height: 120px !important;
  resize: vertical;
  margin-bottom: 50px;
}

.question-root-label {
  display: contents !important;
}

.q-form {
  font-family: "Nunito Sans";
  font-size: 14px;
}

.q-form-span {
  font-family: "Nunito Sans";
  font-size: 14px;
}

.q-button-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.q-input {
  flex-grow: 0;
}

.activity-title {
  background-color: var(--notificationInfo);
  color: var(--text-hover-color);
  text-align: center;
  padding: 3px;
  margin-bottom: 10px;
}

.custom-modal-title {
  color: white !important;
  margin: 10px !important;
  text-align: center !important;
  opacity: 1 !important;
  text-shadow: none !important;
  font-size: 1rem !important;
}

.float-search-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.float-search-button img {
  filter: var(--icons-filter);
}

.custom-color {
  background-color: var(--sidebar-footer-bg) !important;
  color: var(--text-hover-color) !important;
  opacity: .85 !important;
  z-index: 99999 !important;
}

.not-selectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.mobile-toolbar {
  /* position: fixed;
  bottom:0px ;
  left:0px;
  padding-left: 0px; */

}

.menu-button {
  background-color: var(--other-button-bg);
  border: none;
  color: var(--text-color);
  cursor: pointer;
  justify-content: center;
  padding-left: 1.75em;
  padding-right: 1.75em;
  padding-top: .5em;
  margin-top: .75em;
  padding-bottom: .5em;
  font-weight: bold;

  text-align: center;
  white-space: nowrap;
}

.search-bar-small-screen {
  left: auto !important;
}

.small-screen-sub-menu {
  left: 100px !important;
  bottom: 45px !important;
  margin: auto !important;
  position: fixed !important;
}

.mouse-options {
  width: 134px;
  margin-left: 30px;
}

.zoom-options {
  width: 174px;
  margin-left: 93px;
}
.zoom-options-zoom-mode {
  width: 174px;
  margin-left: 30px;
}

.zoom-options-small-screen {
  width: 115px !important;
  margin-left: 90px !important;
}

.devmode-tools-small-screen {
  margin-left: 210px !important;
}

.question-options-small-screen {
  width: 185px !important;
  margin-left: 120px !important;
}


.activity-options {
  width: 383px;
  margin-left: 29px;
}

.question-options {
  width: 214px;
  margin-left: 233px;
}

.teacher-tools {
  width: 214px;
  margin-left: 191px;
}
.teacher-tools-zoom {
  width: 134px;
  margin-left: 121px;
}

.alternative-openable-menu .teacher-tools {
  width: 174px;
  margin-left: 305px;
}

.devmode-tools {
  width: 88px;
  margin-left: 331px;
}

.react-confirm-alert-overlay {
  background-color: rgba(46, 43, 43, 0.7) !important;
  z-index: 1051;

}

.preview-pic {
  margin: auto;
  display: flex;
  align-self: center;
  width: 60%;
}

.preview-pic img {
  height: 30vh;
  object-fit: cover;
  margin: auto;
}

.preview-pic-loading {
  margin: auto;
  display: flex;
  align-self: center;
  width: 20%;
}

.preview-pic-loading img {
  height: 5vh;
  object-fit: cover;
  margin: auto;
}

.modal-button-light {
  margin: -1rem -1rem -1rem auto;
  padding: 0px !important;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.modal-backdrop.show {
  opacity: 0;
}

.modal-head {
  background-color: var(--sidebar-bg);
  color: var(--text-hover-color);
}

.modal-button {
  background: transparent;
  border: none;
}

.bg-fade-0 {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0;
}

.bg-fade-05 {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .5;
}



.modal-content {
  resize: both;
  width: fit-content;
  min-width: 200px;
  min-height: 100px;
  -webkit-box-shadow: 0px 2px 39px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 39px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 39px -9px rgba(0, 0, 0, 0.75);
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.bottom-annotation-menu {
  background-color: var(--sidebar-bg) !important;
  color: var(--text-hover-color);
  margin: auto;
  left: unset !important;
  right: 33%  !important;
  /* width: fit-content; */
  display: table;

  border-radius: 10px 10px 0px 0px !important;
  transition: all 0.3s;

}

.top-annotation-menu {
  background-color: var(--sidebar-bg) !important;
  color: var(--text-hover-color);
  margin: auto;
  width: 400px; /* TODO CHANGE IT TO A NOW PX VAL */
  /* width: fit-content; */
  border-radius: 0px 0px 10px 10px;
  transition: all 0.3s;
}

.top-annotation-menu.hide {
  margin-top: -60px !important;
  box-shadow: none;
}

.top-annotation-menu.open {
  margin-top: 0px !important;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.7);
}

.top-annotation-menu button {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.bottom-annotation-menu.hide {
  margin-bottom: -60px !important;
  box-shadow: none;
}

.bottom-annotation-menu.open {
  margin-bottom: 0px !important;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.7);
  bottom: -15px !important;
  padding-top: .5rem;
}

.bottom-annotation-menu button {
  margin-left: 5px !important;
  margin-right: 5px !important;

}

.float-edit-button {
  position: fixed;
  width: 60px;
  height: 60px;
  background-color: #0C9;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.timer {
  color: var(--text-hover-color);
}

.show-label-span {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.show-label-text {
  color: var(--active-object-bg);
  font-size: xx-small;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.player-wrapper-add-form {
  position: relative;
  padding-top: 2%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.button-low-opacity {
  opacity: .2;
}

.button-disabled {

  box-shadow: none;
  opacity: .5;
  cursor: not-allowed !important;

}

h3 {
  font-size: 1.25rem;
}

h2 {
  font-size: 1.5rem;
}

.no-top-padding {
  padding-top: 0 !important;
}

.timer-div {
  min-width: 120px;
}

.green-bottom-border {
  border-bottom: 2px solid var(--notificationError);
}

.curtain-mode-title {
  margin-left: 5px !important;
  margin-right: 5px !important;
  font-size: .75rem;
  height: 1.5em;
  align-items: center;
  justify-content: center;
}

.horizontal-slider {
  width: 100px;
  height: 14px;
  border-radius: 25px;
}

.opacity-thumb {
  font-size: 0.5em;
  text-align: center;
  background: url('opacity.svg');
  background-size: cover;

  color: rgba(0, 0, 0, .0);
  cursor: pointer;
  top: 02px;
  width: 22px;
  height: 22px;
  border-radius: 20px;
}


.opacity-track {
  position: relative;
  background: var(--icon-bg);
}

.opacity-track.opacity-track-1 {
  background: var(--icon-bg);

}

.horizontal-slider .opacity-track {
  top: 11px;
  height: 4px;
  border-radius: 16px;
  margin: 0 6px;
}

.simple-thumb {
  font-size: 0.5em;
  text-align: center;
  background-color: var(--text-hover-color);
  background-size: cover;

  color: rgba(0, 0, 0, .0);
  cursor: pointer;
  top: 2px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: none;
}


.slider-brightness {
  margin: 6px;
}

.brightness-menu {
  width: 300px !important;
  flex-direction: column;
  align-items: center;
}

.simple-track {
  position: relative;
  background: var(--icon-bg);
}

.simple-track.simple-track-1 {
  background: var(--icon-bg);

}

.horizontal-slider .simple-track {
  top: 10px;
  height: 4px;
  border-radius: 16px;
}

.top-color-picker {
  position: absolute;
  top: 55px;
  z-index: 200;
  background-color: var(--sidebar-bg) !important;
  color: var(--text-hover-color);
  margin: auto;
  left: 50%;
  transform: translate(-50%, 0%);
  width: fit-content;
  border-radius: 0px 0px 10px 10px;
  transition: all 0.3s;

}

.brightness-sub-div {
  display: flex;
  flex-direction: row;
  margin: 6px;
}

.suggested-words-title {
  font-weight: 500;
  background-color: var(--text-hover-color);
  text-align: center;
}

.main-toolbar-alternative {
  height: 100px;
  padding-top: 4px !important;
}

.main-toolbar-alternative .has-addons {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.library-company-logo {
  width: 120px;
  margin-top: -6px;
  margin-left: 26px;
}

.library-indivi-logo {
  width: 190px;
  margin-top: -3px;
  margin-left: 26px;
}

.library-logout {
  padding-top: 10px;
  font-size: 16px;
}

.library-username {
  padding-top: 0px;
  font-size: 16px;
}

.main-button-alternative {
  border: none !important;
}

.ql-align-center {
  text-align: center;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  z-index: -1;
  transition: opacity .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  z-index: 999;


}
