@media screen and (max-width: 579px) {

    .mobile-toolbar {
      /* position: fixed; */
      bottom: 0px;
      /* left:0px; */
      /* margin: auto 0 !important; */
      padding-left: 0px;
    }

    /* LOGIN FORM */
    .login-form {
      display: block;
      width: 90%;
      margin: 0 auto;
      height: fit-content;
      border-radius: 16px;
      margin: auto;
    }

    .login-form-area {
      /* height: 100%; */
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--body-bg);
      margin: auto;
    }

    .lf-left {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      /* align-items: flex-start; */
      justify-content: center;
      height: fit-content;
      padding: 0 30px;
      border-radius: 16px 16px 0 0;
    }

    .lf-right {
      width: 100%;
      height: fit-content;
      padding: 20px 30px 15px;
      background: #f7f7f7;
      border-radius: 0 0 16px 16px;
    }

    .lf-left-title {
      display: none;
    }

    .lf-left-title span {
      border-bottom: 2px solid #fff;
    }

    .lf-left-text {
      margin-bottom: 20px;
    }

    .lf-right-title {
      font-size: 26px;
      margin-bottom: 10px;
    }

    .login-selection {
      position: absolute;
    }

    .library-selection {
      bottom: 10px;
      right: 10px;
      margin-top: 0px !important;
      margin-right: 5px !important;
    }

    .lf-left-notice {
      display: none;
    }

    .bookmark {
      width: 5vw !important;
      height: 5vW !important;
      padding: .5vw !important;
    }

    .welcome-user {
      /* font-size: 14px !important; */
      /* line-height: 1; */
      padding-top: 2px;
      /* text-transform: capitalize; */
    }

    .library-company-logo {
      width: 70px !important;
      margin-left: 3px !important;
    }

    .library-indivi-logo {
      width: 90px !important;
      margin-top: -3px;
      margin-left: 3px !important;
    }

    .library-selection .select-area {
      text-align: left;
      margin-top: 0;
      padding-left: 0px !important;
      padding-top: 0px !important;
    }

    .library-logout {
      padding-top: 10px;
      font-size: 12px !important;
    }

    .library-username {
      padding-top: 0px;
      font-size: 12px !important;
    }

  }