@media screen and (max-width: 1023px) {
    .sidebar {
      margin-left: -160px !important;
      position: absolute;
      z-index: 10;
      box-shadow: none;
    }

    .searchbar {
      margin-right: -200px !important;
      position: absolute;
      right: 0;
      z-index: 10;
      box-shadow: none;
    }

    .searchbar.hide {
      visibility: hidden;
    }

    .sidebar.hide {
      margin-left: -160px !important;
      position: absolute;
      z-index: 10;
      box-shadow: none;
    }



    .sidebar.open {
      margin-left: 0px !important;
      position: absolute;
      z-index: 10;
      box-shadow: 8px 0 8px rgba(0, 0, 0, 0.7);
    }

    .searchbar.open {
      margin-right: 0px !important;
      position: absolute;
      z-index: 100;
      box-shadow: 8px 0 8px rgba(0, 0, 0, 0.7);
    }

    .collapse-sidebar {
      position: fixed;
      bottom: 3px;
      left: -3px;
      transform: rotate(180deg);
      z-index: 1;
      border-color: var(--border-light-color);
      background-color: var(--sidebar-footer-bg);
    }

    .collapse-searchbar {
      position: fixed;
      bottom: 3px;
      right: -3px;
      transform: rotate(0deg);
      z-index: 1;
      border-color: var(--border-light-color);
      background-color: var(--sidebar-footer-bg);
    }

    .sidebar.open .collapse-sidebar {
      position: static;
      transform: rotate(0deg);
    }

    .searchbar.open .collapse-searchbar {
      position: absolute;
      transform: rotate(180deg);
      right: auto;
      left: 3px;
    }

    .pdf-page-list {
      padding: 25px 25px 0;
      cursor: pointer;
    }

    .qhideable-top-margin {
      padding-top: 50px;
    }

    .pdf-page {
      margin-top: -15px;
    }

    .pdf-page figure:not(:last-child) {
      margin: 0 0 15px;
    }

    .toolbar-wrapper {
      bottom: 55vh;
      left: calc(85% - 284px);
    }

    .toolbar-wrapper-change-left {
      left: calc(70% - 303px);
    }

    .toolbar-intro-sudo-class {
        height: 0px;
        width: 0px;
        left: calc(90% - 110px);
        bottom: 48vh;
        position: absolute;
        zIndex: 10;
      }



  }
