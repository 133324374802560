@media screen and (max-width: 768px) {
    .is-loading-anim {
        margin: auto;
        right: 3vw;
        top: 0px;
        position: absolute;
    }


    .text-tools {
        height: 32px;
        width: 248px;
    }

    .text-tools .button {
        height: 30px;
        width: 30px;
    }

    .text-size select {
        height: 30px;
    }

    .text-size .select:not(.is-multiple) {
        height: 30px;
    }

    .text-size .icon {
        top: 9px;
    }

    .text-font-family select {
        height: 30px;
    }

    .text-font-family .select:not(.is-multiple) {
        height: 30px;
    }

    .text-font-family .icon {
        top: 9px;
    }

    .toolbar-wrapper {
        /* bottom: 35px; */
        bottom: 62vh;
        left: calc(90% - 308px);
    }

    .toolbar-wrapper-change-left {
        left: calc(70% - 303px);
    }


    .main-navbar {
        height: 40px;
        width: auto;
    }



    /* LOGIN FORM */
    .login-form {
        width: 540px;
        height: 400px;
        border-radius: 16px;
    }


    .lf-left {
        position: relative;

        /* width: 270px; */
        /* height: 400px; */
        /* padding: 60px 30px; */
        /* background: #2b2c2f; */
        /* border-radius: 16px 0 0 16px; */
    }

    .lf-left-title {
        font-size: 24px;
        font-weight: 300;
        /* color: #fff; */
        /* margin-bottom: 25px; */

    }

    .lf-right {
        /* width: 270px; */
        /* height: 400px; */
        /* padding: 60px 30px; */
        /* background: #f7f7f7; */
        /* border-radius: 0 16px 16px 0; */
    }

    .mobile-toolbar {
        /* position: fixed; */
        /* bottom: 0px; */
        /* left:0px; */
        /* margin: auto 0 !important; */
        padding-left: 0px;
    }

    .welcome-logo {
        /* width: 50%; */
        padding-bottom: 35px;
        padding-top: 0px;
        /* padding-left: 10px; */
        margin: auto;

        max-width: 70%;
    }

    .welcome-logo-company {
        /* width: 50%; */
        padding-bottom: 15px;
        padding-top: 20px;
        /* padding-left: 10px; */
        margin: auto;

        max-width: 70%;
    }

    .bookmark {
        width: 5vw !important;
        height: 5vW !important;
        padding: .5vw !important;
    }

    .activity-options {
        width: 383px !important;
        margin-left: 29px;
    }


}